import React, {useEffect, useState, useCallback, useContext} from 'react'
import {useTranslation} from "react-i18next"
import {fetchOrdersList, searchOrders} from "./container"
import {authContext} from "authentication/auth"
import {getParam, urlParams, getParams} from "helpers/utils"
import axios from "axios"
import OrdersListView from "./orderListView"
import {showConfirm} from "plugins/confirm"
import {deleteOrders, updateMultiple} from "pages/orders/container"

const OrdersListOpen = () => {
	const [data, setData] = useState({ready: false, last_page: 1, current_page: 1, isSearched: false})
	const [appliedFilters, setAppliedFilters] = useState({})
	const [selectedItems, setSelectedItems] = useState([])
	const {t} = useTranslation()
	let currentPage = 1
	const source = axios.CancelToken.source()

	const userAuth = useContext(authContext)
	const sellerId = userAuth ? userAuth.user.seller_id : ''

	useEffect(() => {
		document.title = t('Đơn hàng đang chờ')
		const params = getParams()
		if (urlParams.has('page')) {
			currentPage = parseInt(getParam('page')) || 1
		}
		let result, isSearched = false
		const fetchData = async () => {
			try {
				if (Object.keys(params).length > 0) {
					params.seller_id = sellerId
					params.order_status = 'open'
					result = await searchOrders(params, source.token)

					if (result) isSearched = true
				} else {
					result = await fetchOrdersList({page: currentPage, seller_id: sellerId, order_status: 'open'}, source.token)
				}
				if (result) {
					setData({...data, ...result, ready: true, isSearched: isSearched})
				}
			} catch (error) {
				if (axios.isCancel(error)) {
					console.log('is canceled')
				} else {
					throw error;
				}
			}
		}
		fetchData()
		return () => source.cancel()
	}, [window.location.search])

	const handleSearch = () => {
		const params = getParams()
		params.seller_id = sellerId
		params.order_status = 'open'
		;(async () => {
			const result = await searchOrders(params, source.token)
			setData({...result, ready: true, isSearched: true})
		})()
	}

	const promotedBulkActions = [
		{
			content: 'Xóa đơn hàng',
			onAction: () => {
				showConfirm({
					title: t('Xóa đơn hàng'),
					message: t('Bạn có chắc chắn muốn xóa những đơn hàng này?'),
					confirm: t('Delete'),
					cancel: t('Đóng'),
					danger: true
				}).then(res => {
					if (res) {
						deleteOrders(selectedItems)
					}
				})
				return false
			}
		},
		{
			content: 'Hủy đơn hàng',
			onAction: () => {
				showConfirm({
					title: t('Hủy đơn hàng'),
					message: t('Bạn có chắc chắn muốn hủy những đơn hàng này?'),
					confirm: t('Hủy đơn hàng'),
					cancel: t('Đóng'),
					danger: true
				}).then(res => {
					if (res) {
						updateMultiple(selectedItems, 'canceled')
					}
				})
				return false
			}
		},
	]

	const headers = [
		{
			id: 'customer',
			title: t('Khách hàng'),
			width: 45
		},
		{
			id: 'order_status',
			title: t('Order status'),
			width: 15
		}
	]
	return (
		<OrdersListView
			title={'Đơn hàng đang chờ'}
			data={data}
			handleSearch={handleSearch}
			appliedFilters={appliedFilters}
			selectedItems={selectedItems}
			setSelectedItems={setSelectedItems}
			setAppliedFilters={setAppliedFilters}
			promotedBulkActions={promotedBulkActions}
			dataHeader={headers}
		/>
	)
}
export default OrdersListOpen
