import React from 'react'
import {Router} from "@reach/router"
import OrdersList from "../pages/sellerOrders/list"
import OrdersListOpen from "../pages/sellerOrders/listOpen"
import OrdersListProcessing from "../pages/sellerOrders/listProcessing"
import OrdersListShipping from "../pages/sellerOrders/listShipping"
import OrdersListDone from "../pages/sellerOrders/listDone"
import CreateOrder from "../pages/sellerOrders/create"
import EditOrder from "../pages/sellerOrders/edit"

export default function OrderOrders() {
  return (
	<Router>
	  <OrdersList path="/" />
	  <OrdersListOpen path="/open" />
	  <OrdersListProcessing path="/processing" />
	  <OrdersListShipping path="/shipping" />
	  <OrdersListDone path="/shipped" />
	  <CreateOrder path="/new" />
	  <EditOrder path="/edit/:orderID" />
	</Router>
  )
}
