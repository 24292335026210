import React from 'react'
import {Navigation} from '@shopify/polaris'
import {useTranslation} from "react-i18next"
import NavSection from "modules/navSection"
import {AuthConsumer} from "authentication/auth"
import {
	HomeMajorMonotone,
	PrintMajorMonotone,
	LabelPrinterMajorMonotone,
	OrdersMajorMonotone,
	ChecklistMajorMonotone,
	ShipmentMajorMonotone, ProfileMinor
} from '@shopify/polaris-icons'

const AppNavigation = () => {
	const {t} = useTranslation()
	return (
		<AuthConsumer>
			{({authenticated}) => authenticated ? (
				<Navigation location="/">
					<NavSection
						separator
						items={[
							{
								label: t('Dashboard'),
								icon: HomeMajorMonotone,
								url: '/'
							},
							// {
							// 	label: t('Orders'),
							// 	icon: OrdersMajorMonotone,
							// 	url: '/orders',
							// 	subNavigationItems: [
							// 		{
							// 			label: t('All'),
							// 			url: '/orders',
							// 		},
							// 		{
							// 			label: t('Pending'),
							// 			url: '/orders/open',
							// 		},
							// 		{
							// 			label: 'Đang sản xuất',
							// 			url: '/orders/processing',
							// 		},
							// 		{
							// 			label: t('Orders shipping'),
							// 			url: '/orders/shipping',
							// 		},
							// 		{
							// 			label: t('Giao thành công'),
							// 			url: '/orders?order_status=completed',
							// 		},
							// 		{
							// 			label: t('Không thành công'),
							// 			url: '/orders?order_status=incompleted',
							// 		},
							// 		{
							// 			label: t('Orders canceled'),
							// 			url: '/orders?order_status=canceled',
							// 		}
							// 	]
							// },
						]}
					/>
					<NavSection
						separator
						fill
						title={'Đơn hàng'}
						items={[
							{
								label: t('Tất cả'),
								icon: OrdersMajorMonotone,
								url: '/orders',
								subNavigationItems: [
									{
										label: t('All'),
										url: '/orders',
									},
									{
										label: t('Không thành công'),
										url: '/orders?order_status=incompleted',
									},
									{
										label: t('Orders canceled'),
										url: '/orders?order_status=canceled',
									}
								]
							},
							{
								label: t('Đang chờ'),
								icon: LabelPrinterMajorMonotone,
								url: '/orders/open'
							},
							{
								label: t('Đang sản xuất'),
								icon: PrintMajorMonotone,
								url: '/orders/processing'
							},
							{
								label: t('Đang vận chuyển'),
								icon: ShipmentMajorMonotone,
								url: '/orders/shipping'
							},
							{
								label: t('Giao thành công'),
								icon: ChecklistMajorMonotone,
								url: '/orders/shipped'
							}
						]}
					/>
					<NavSection
						items={[
							{
								label: t('Tài khoản'),
								icon: ProfileMinor,
								url: '/manage/profile',
							}
						]}
					/>
				</Navigation>
			) : null}
		</AuthConsumer>
	)
}
export default AppNavigation
