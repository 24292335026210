import React, {Fragment, useContext, useEffect, useState} from 'react'
import {Button, ButtonGroup, Layout, Page, PageActions, Badge, Banner} from "@shopify/polaris"
import {useTranslation} from "react-i18next"
import StickyActions from "modules/stickyActions"
import {AuthConsumer} from "authentication/auth"
import {navigate, Redirect} from "@reach/router"
import {showToast} from "plugins/toast"
import {Skeleton} from "modules/skeleton"
import {createSubscription, useSubscription} from "global-state-hook"
import {fetchOrder, initialState, OrderContext, updateOrder, deleteOrders} from "core/pages/orders/container"
import {showConfirm} from "plugins/confirm"
import Can from "authentication/can"
import Summary from "./components/summary"
import CNote from "pages/orders/components/cnote"
import Shipping from "./components/shipping"
import Customer from "pages/orders/components/customer"
import {CalendarMinor, CancelSmallMinor} from '@shopify/polaris-icons'
import moment from 'moment'
import OrderStatusBadge from "modules/orderStatus"


const EditOrder = (props) => {
	const orderSub = createSubscription({id: props.orderID, ...initialState})

	return <OrderContext.Provider value={orderSub}>
		<EditOrderView {...props}/>
	</OrderContext.Provider>

}


const EditOrderView = (props) => {
	const {t} = useTranslation()

	const [ready, setReady] = useState(false)
	const [saving, setSaving] = useState(false)
	const orderSub = useContext(OrderContext)
	let {setState, state} = useSubscription(orderSub)

	useEffect(() => {
		document.title = t('Update order')
		const fetchData = async () => {
			const result = await fetchOrder(props.orderID)
			setState(result)
			setReady(true)
			console.log('Data details', result)
		}
		fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.orderID])

	useEffect(() => {
		document.title = `Update ${state.title}`
	}, [state.title])

	const handleSave = (data) => {
		if (!data) data = state
		setSaving(true)
		updateOrder(data).then((res) => {
			console.log(res.order_status, 'order res')
			showToast({
				message: 'Đơn hàng đã được cập nhật'
			})
		}).catch((error) => {
			console.error(error)
			showToast({
				message: 'Something went wrong, please try again',
				error: true
			})

		}).finally(() => {
			setSaving(false)
		})
	}
	const handleCancel = () => {
		showConfirm({
			title: `Hủy đơn hàng`,
			message: t('Bạn có chắc chắn muốn hủy đơn hàng này, hành động này sẽ không thể khôi phục lại.'),
			confirm: t('Hủy đơn hàng'),
			cancel: t('Giữ lại'),
			danger: true
		}).then(res => {
			if (res) {
				setState({...state, order_status: 'canceled'})
				handleSave({...state, order_status: 'canceled'})
			}
		})
		return false
	}
	const handleDelete = () => {
		const {_id, title} = state
		showConfirm({
			title: `Xóa đơn hàng ${title}?`,
			message: 'Hãy chắc chắn rằng bạn muốn xóa đơn hàng này, hành động này sẽ không thể khôi phục lại.',
			confirm: t('Tôi muốn xóa'),
			cancel: t('Đóng'),
			danger: true
		}).then(res => {
			if (res) {
				deleteOrders([_id], true)
			}
		})
		return false
	}
	const {title, created_at, order_status, shipped_at, canceled_at} = state

	let badgeStatus = order_status
	if (order_status === 'printing' || order_status === 'finishing' || order_status === 'storing') badgeStatus = 'processing'

	let bannerStatus, bannerTitle, bannerDesc
	switch (order_status) {
		case 'shipped':
			bannerStatus = 'success'
			bannerTitle = 'Giao hàng thành công'
			bannerDesc = 'Đơn hàng đã giao thành công lúc ' + moment(shipped_at).format('DD-MM-YY HH:mm')
			break
		case 'canceled':
			bannerStatus = 'critical'
			bannerTitle = 'Đơn hàng đã bị hủy'
			bannerDesc = 'Đơn hàng đã hủy lúc ' + moment(canceled_at).format('DD-MM-YY HH:mm')
			break
		case 'incompleted':
			bannerStatus = ''
			bannerTitle = 'Giao hàng không thành công'
			bannerDesc = 'Giao hàng không thành công lúc ' + moment(shipped_at).format('DD-MM-YY HH:mm')
			break

	}
	return (
		<AuthConsumer>
			{({authenticated, user}) => {
				return authenticated ? (
					<Can
						role={user.role}
						perform="orders:edit"
						yes={() => (
							<Fragment>
								{ready ? (
									<Fragment>
										{order_status === 'open' && (
											<StickyActions
												actions={(
													<ButtonGroup>
														<Button onClick={() => navigate('/orders')}>{t('Discard')}</Button>
														{order_status === 'open' && <Button destructive monochrome outline icon={CancelSmallMinor} onClick={handleCancel}>{t('Hủy đơn')}</Button>}
													</ButtonGroup>
												)}
											/>
										)}
										<Page
											title={title}
											fullWidth
											subtitle={<Button icon={CalendarMinor} disabled plain>{moment(created_at).format('YYYY-MM-DD HH:mm')}</Button>}
											breadcrumbs={[{content: t('Orders'), onAction: () => window.history.back()}]}
											titleMetadata={<Fragment>
												<OrderStatusBadge order_status={badgeStatus} />
											</Fragment>}
										>
											{['shipped', 'incompleted', 'canceled'].includes(order_status) && (
												<div style={{marginBottom: '2rem'}}>
													<Banner title={bannerTitle} status={bannerStatus}>
														<p>{bannerDesc}</p>
													</Banner>
												</div>
											)}
											<Layout>
												<Layout.Section>
													<Summary user={user} />
													<Shipping/>
												</Layout.Section>
												<Layout.Section secondary>
													<CNote />
													<Customer user={user} />
												</Layout.Section>
											</Layout>
											{order_status === 'open' && (
												<PageActions
													secondaryActions={order_status === 'open' ? [
														{
															content: t('Xóa đơn hàng'),
															onAction: handleDelete,
															loading: saving,
															destructive: true
														}
													] : undefined}
												/>
											)}
										</Page>
									</Fragment>
								) : <Skeleton/>}
							</Fragment>
						)}
					/>
				) : <Redirect to="/login" noThrow/>
			}}
		</AuthConsumer>
	)
}

export default EditOrder
