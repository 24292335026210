import React, {useContext} from 'react'
import {Card, Badge, TextContainer, TextStyle} from '@shopify/polaris'
import {useTranslation} from "react-i18next"
import {OrderContext} from "pages/orders/container"
import {useSubscription} from "global-state-hook"

export default function Seller({user}) {
	console.log(user, 'seller')
	const {t} = useTranslation()
	const fullname = [user.first_name, user.last_name].filter(v => v !== '')
	return (
		<Card title={t('Seller')}>
			<Card.Section>
				<TextContainer>
					<TextStyle variation={'strong'}>{fullname.join(' ')} <Badge status={'info'}>{user.seller_id}</Badge></TextStyle>
					<p>{t('Email')}: {user.email ? user.email : t('No email address')}</p>
					<p>{t('Phone')}: {user.phone ? user.phone : t('No phone number')}</p>
				</TextContainer>
			</Card.Section>
		</Card>
	)
}
