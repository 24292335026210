import React from 'react'

const logo = (
	<svg viewBox="0 0 1280 492.75" width="130">
		<g opacity="0.09">
			<path d="M381,151.39V41.56L500.23,88.33V23l44.14,18.86v109l-117.6-47.59v48.12Zm722.48-44.6H1280v44.07H1147.59v53.31L1235.86,285V224.3h-21.48l-44.14-44.07H1280v207.4L1103.45,226.06Zm-191.27-9.1L1044.6,229.88V106.79h44.14V336.22L956.32,204V327.11H912.18ZM735.63,387.63V106.79h44.14V285.25l73.56-66.68V106.79h44.14V327.11H853.33V280.85ZM603.22,150.86V285.25l117.7-106.78v62.28L559.08,387.63V106.79H720.92v44.07ZM382.53,387.63V180.23h44.14v105l73.56-66.68v-59.3l44.14,19.2V333.11H500.23V280.85ZM191.26,106.79H367.82v44.07H235.4v53.31L323.68,285V224.3H302.2l-44.14-44.07H367.82v207.4L191.26,226.06ZM0,97.69,132.41,229.88V106.79h44.14V336.22L44.14,204V336.22L0,387.63Z" fillRule="evenodd"></path>
		</g>
		<path d="M33.62,480.63h-19l-4,11.44H1.73l18.56-49.14H28l18.6,49.14H37.64ZM17,473.74H31.23l-7.12-20.38Z" fill="#fff"></path>
		<path d="M99,479.92l7.56-37h8.47l-11.34,49.14H95.54L86.2,456.19l-9.56,35.88h-8.2L57.1,442.93h8.47l7.63,36.92,9.38-36.92h7.16Z" fill="#fff"></path>
		<path d="M157,480.63H138l-4,11.44h-8.88l18.57-49.14h7.66L170,492.07H161Zm-16.64-6.89h14.24l-7.12-20.38Z" fill="#fff"></path>
		<path d="M199.31,470.84l-5.57,5.91v15.32H185.2V442.93h8.54V466l4.72-5.84,14.38-17.21h10.33L204.91,464.7l19.3,27.37H214.09Z" fill="#fff"></path>
		<path d="M267.17,470.13H247v15.12h23.59v6.82H238.45V442.93h31.89v6.89H247v13.56h20.18Z" fill="#fff"></path>
		<path d="M326.19,492.07h-8.54l-21.9-34.86v34.86h-8.54V442.93h8.54l22,35v-35h8.47Z" fill="#fff"></path>
		<path d="M388.88,466.22l11.3-23.29h9.45L393.16,474v18.09h-8.57V474l-16.5-31.05h9.48Z" fill="#fff"></path>
		<path d="M462.88,468.78a30.11,30.11,0,0,1-2.5,12.68,18.86,18.86,0,0,1-7.14,8.37,20.92,20.92,0,0,1-21.32,0,19.2,19.2,0,0,1-7.23-8.32,29.15,29.15,0,0,1-2.58-12.46v-2.76a29.82,29.82,0,0,1,2.55-12.69,19.16,19.16,0,0,1,7.19-8.43,21,21,0,0,1,21.29,0,18.88,18.88,0,0,1,7.18,8.3,29.93,29.93,0,0,1,2.56,12.61Zm-8.54-2.56q0-8.18-3.09-12.52a10.88,10.88,0,0,0-17.41,0Q430.72,458,430.65,466v2.83q0,8.1,3.15,12.52a10.18,10.18,0,0,0,8.76,4.43,10.06,10.06,0,0,0,8.73-4.32q3-4.32,3-12.63Z" fill="#fff"></path>
		<path d="M517,442.93v32.84q0,7.83-5,12.4t-13.34,4.58q-8.44,0-13.4-4.51t-5-12.5V442.93h8.5V475.8q0,4.93,2.5,7.53c1.66,1.73,4.12,2.6,7.36,2.6q9.86,0,9.85-10.4v-32.6Z" fill="#fff"></path>
		<path d="M553.85,473.17h-9.52v18.9H535.8V442.93h17.28q8.5,0,13.12,3.82t4.63,11a13.88,13.88,0,0,1-2.38,8.26,15.15,15.15,0,0,1-6.63,5.12l11,20.46v.44h-9.14Zm-9.52-6.88h8.78a9.77,9.77,0,0,0,6.75-2.18,7.6,7.6,0,0,0,2.43-6A8.08,8.08,0,0,0,560,452q-2.24-2.16-6.69-2.22h-9Z" fill="#fff"></path>
		<path d="M626.56,442.93l14.18,37.67,14.14-37.67h11v49.14h-8.51v-16.2l.84-21.67-14.51,37.87h-6.11l-14.48-37.83.85,21.63v16.2h-8.51V442.93Z" fill="#fff"></path>
		<path d="M721.63,442.93v32.84q0,7.83-5,12.4t-13.35,4.58q-8.44,0-13.4-4.51t-5-12.5V442.93h8.5V475.8c0,3.29.84,5.8,2.5,7.53s4.12,2.6,7.36,2.6q9.86,0,9.85-10.4v-32.6Z" fill="#fff"></path>
		<path d="M766.1,479.41a5.92,5.92,0,0,0-2.28-5q-2.28-1.76-8.22-3.54a39.67,39.67,0,0,1-9.45-4q-6.71-4.22-6.71-11a12,12,0,0,1,4.84-9.79q4.85-3.84,12.57-3.84a21.26,21.26,0,0,1,9.15,1.89,15,15,0,0,1,6.31,5.38,13.81,13.81,0,0,1,2.3,7.74H766.1a7.69,7.69,0,0,0-2.41-6q-2.41-2.17-6.9-2.18a10.43,10.43,0,0,0-6.5,1.79,6,6,0,0,0-2.31,5,5.38,5.38,0,0,0,2.49,4.5,28.46,28.46,0,0,0,8.24,3.51,38,38,0,0,1,9.21,3.88,14.72,14.72,0,0,1,5.1,5,13,13,0,0,1,1.62,6.6,11.7,11.7,0,0,1-4.71,9.77q-4.71,3.63-12.77,3.63a24,24,0,0,1-9.81-2,16.38,16.38,0,0,1-7-5.45,13.62,13.62,0,0,1-2.48-8.1h8.54a7.93,7.93,0,0,0,2.77,6.48q2.76,2.3,7.93,2.3c3,0,5.2-.61,6.7-1.81A5.84,5.84,0,0,0,766.1,479.41Z" fill="#fff"></path>
		<path d="M800.82,492.07h-8.51V442.93h8.51Z" fill="#fff"></path>
		<path d="M858.49,476.07q-.75,7.88-5.81,12.27t-13.46,4.41A19.14,19.14,0,0,1,828.87,490a18.29,18.29,0,0,1-6.9-7.91,28.6,28.6,0,0,1-2.53-11.92v-4.59a28.81,28.81,0,0,1,2.46-12.25,18.68,18.68,0,0,1,7.07-8.17,19.76,19.76,0,0,1,10.65-2.86q8.13,0,13.1,4.4t5.77,12.47H850q-.6-5.29-3.08-7.64c-1.66-1.57-4.08-2.35-7.28-2.35a10,10,0,0,0-8.55,4.07c-2,2.71-3,6.7-3.06,11.94v4.36q0,8,2.86,12.16a9.46,9.46,0,0,0,8.35,4.19q5,0,7.56-2.26c1.69-1.51,2.75-4,3.2-7.53Z" fill="#fff"></path>
		<path d="M911.69,492.07h-8.5V442.93h8.5Z" fill="#fff"></path>
		<path d="M970.85,492.07h-8.54l-21.9-34.86v34.86h-8.54V442.93h8.54l22,35v-35h8.47Z" fill="#fff"></path>
		<path d="M1016.3,479.41a5.92,5.92,0,0,0-2.28-5q-2.28-1.76-8.22-3.54a39.67,39.67,0,0,1-9.45-4q-6.71-4.22-6.71-11a12,12,0,0,1,4.84-9.79q4.85-3.84,12.57-3.84a21.26,21.26,0,0,1,9.15,1.89,15,15,0,0,1,6.31,5.38,13.8,13.8,0,0,1,2.29,7.74h-8.5a7.69,7.69,0,0,0-2.41-6q-2.42-2.17-6.91-2.18a10.39,10.39,0,0,0-6.49,1.79,6,6,0,0,0-2.31,5,5.38,5.38,0,0,0,2.49,4.5,28.46,28.46,0,0,0,8.24,3.51,38.18,38.18,0,0,1,9.21,3.88,14.72,14.72,0,0,1,5.1,5,13,13,0,0,1,1.62,6.6,11.7,11.7,0,0,1-4.71,9.77q-4.71,3.63-12.77,3.63a24,24,0,0,1-9.81-2,16.38,16.38,0,0,1-6.95-5.45,13.62,13.62,0,0,1-2.48-8.1h8.54a8,8,0,0,0,2.76,6.48c1.85,1.53,4.49,2.3,7.94,2.3,3,0,5.2-.61,6.7-1.81A5.84,5.84,0,0,0,1016.3,479.41Z" fill="#fff"></path>
		<path d="M1077.51,449.82h-15.32v42.25h-8.47V449.82h-15.19v-6.89h39Z" fill="#fff"></path>
		<path d="M1102.65,492.07h-8.51V442.93h8.51Z" fill="#fff"></path>
		<path d="M1161.8,492.07h-8.54l-21.9-34.86v34.86h-8.54V442.93h8.54l22,35v-35h8.47Z" fill="#fff"></path>
		<path d="M1219.07,476.07q-.75,7.88-5.81,12.27t-13.47,4.41a19.13,19.13,0,0,1-10.34-2.79,18.29,18.29,0,0,1-6.9-7.91,28.45,28.45,0,0,1-2.53-11.92v-4.59a28.81,28.81,0,0,1,2.46-12.25,18.62,18.62,0,0,1,7.07-8.17,19.74,19.74,0,0,1,10.65-2.86q8.13,0,13.09,4.4t5.78,12.47h-8.51q-.6-5.29-3.09-7.64c-1.65-1.57-4.08-2.35-7.27-2.35a10,10,0,0,0-8.56,4.07c-2,2.71-3,6.7-3.05,11.94v4.36q0,8,2.85,12.16a9.47,9.47,0,0,0,8.35,4.19q5,0,7.56-2.26t3.21-7.53Z" fill="#fff"></path>
		<path d="M1270.9,449.82h-15.33v42.25h-8.47V449.82h-15.18v-6.89h39Z" fill="#fff"></path>
		<path d="M381,128.39V18.56L500.23,65.33V0l44.14,18.86v109L426.77,80.27v48.12Zm722.48-44.6H1280v44.07H1147.59v53.31L1235.86,262V201.3h-21.48l-44.14-44.07H1280v207.4L1103.45,203.06Zm-191.27-9.1L1044.6,206.88V83.79h44.14V313.22L956.32,181V304.11H912.18ZM735.63,364.63V83.79h44.14V262.25l73.56-66.68V83.79h44.14V304.11H853.33V257.85ZM603.22,127.86V262.25l117.7-106.78v62.28L559.08,364.63V83.79H720.92v44.07ZM382.53,364.63V157.23h44.14v105l73.56-66.68v-59.3l44.14,19.2V310.11H500.23V257.85ZM191.26,83.79H367.82v44.07H235.4v53.31L323.68,262V201.3H302.2l-44.14-44.07H367.82v207.4L191.26,203.06ZM0,74.69,132.41,206.88V83.79h44.14V313.22L44.14,181V313.22L0,364.63Z" fill="#fff" fillRule="evenodd"></path>
	</svg>
)

const home = (
	<svg viewBox="0 0 20 20" focusable="false" width={20} height={20}>
		<g fillRule="evenodd">
			<path fill="currentColor" d="M7 13h6v6H7z"></path>
			<path
				d="M19.664 8.252l-9-8a1 1 0 0 0-1.328 0L8 1.44V1a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v5.773L.336 8.252a1.001 1.001 0 0 0 1.328 1.496L2 9.449V19a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V9.449l.336.299a.997.997 0 0 0 1.41-.083 1.001 1.001 0 0 0-.082-1.413zM16 18h-2v-5a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v5H4V7.671l6-5.333 6 5.333V18zm-8 0h4v-4H8v4zM4 2h2v1.218L4 4.996V2z"></path>
		</g>
	</svg>
)

const products = (
	<svg viewBox="0 0 20 20" focusable="false" width={20} height={20}>
		<g fillRule="evenodd">
			<path fill="currentColor" d="M4 7l-3 3 9 9 3-3z"></path>
			<path
				d="M19 0h-9c-.265 0-.52.106-.707.293l-9 9a.999.999 0 0 0 0 1.414l9 9a.997.997 0 0 0 1.414 0l9-9A.997.997 0 0 0 20 10V1a1 1 0 0 0-1-1zm-9 17.586L2.414 10 4 8.414 11.586 16 10 17.586zm8-8l-5 5L5.414 7l5-5H18v7.586zM15 6a1 1 0 1 0 0-2 1 1 0 0 0 0 2"></path>
		</g>
	</svg>
)

const orders = (
	<svg viewBox="0 0 20 20" focusable="false" width={20} height={20}>
		<g fillRule="evenodd">
			<path fill="currentColor" d="M1 13h5l1 2h6l1-2h5v6H1z"></path>
			<path
				d="M2 18v-4h3.382l.723 1.447c.17.339.516.553.895.553h6c.379 0 .725-.214.895-.553L14.618 14H18v4H2zM19 1a1 1 0 0 1 1 1v17a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h4a1 1 0 0 1 0 2H2v9h4c.379 0 .725.214.895.553L7.618 14h4.764l.723-1.447c.17-.339.516-.553.895-.553h4V3h-3a1 1 0 0 1 0-2h4zM6.293 6.707a.999.999 0 1 1 1.414-1.414L9 6.586V1a1 1 0 0 1 2 0v5.586l1.293-1.293a.999.999 0 1 1 1.414 1.414l-3 3a.997.997 0 0 1-1.414 0l-3-3z"></path>
		</g>
	</svg>
)

const customers = (
	<svg viewBox="0 0 20 20" focusable="false" width={20} height={20}>
		<g fillRule="evenodd">
			<path d="M10 13c-4 0-7 3-7 3l1 3h12l1-3s-3-3-7-3" fill="currentColor"></path>
			<path
				d="M17.707 15.293a.999.999 0 0 1 .241 1.023l-1 3A.999.999 0 0 1 16 20H4a1 1 0 0 1-.949-.684l-1-3a.999.999 0 0 1 .242-1.023C2.427 15.158 5.635 12 10 12c4.364 0 7.572 3.158 7.707 3.293zM15.28 18l.562-1.687c-.92-.752-3.155-2.313-5.84-2.313-2.704 0-4.928 1.558-5.844 2.31L4.72 18h10.56zM10 2c1.654 0 3 1.346 3 3s-1.346 3-3 3-3-1.346-3-3 1.346-3 3-3zm0 8c2.757 0 5-2.243 5-5s-2.243-5-5-5-5 2.243-5 5 2.243 5 5 5"></path>
		</g>
	</svg>
)

const settings = (
	<svg viewBox="0 0 20 20" focusable="false" width={20} height={20}>
		<g fillRule="evenodd">
			<path
				d="M10 13a3 3 0 1 1 0-6 3 3 0 0 1 0 6m7-3c0-.53-.064-1.044-.176-1.541L19 7.232l-2.047-3.464-2.106 1.188A6.978 6.978 0 0 0 12 3.294V1H8v2.294a6.978 6.978 0 0 0-2.847 1.662L3.047 3.768 1 7.232l2.176 1.227A6.993 6.993 0 0 0 3 10c0 .53.064 1.044.176 1.541L1 12.768l2.047 3.464 2.106-1.188A6.99 6.99 0 0 0 8 16.706V19h4v-2.294a6.978 6.978 0 0 0 2.847-1.662l2.106 1.188L19 12.768l-2.176-1.227A6.993 6.993 0 0 0 17 10"
				fill="currentColor"></path>
			<path
				d="M19.492 11.897l-1.56-.88a7.8 7.8 0 0 0 0-2.035l1.56-.879a1.001 1.001 0 0 0 .37-1.38L17.815 3.26a1.001 1.001 0 0 0-1.353-.362l-1.491.841A8.078 8.078 0 0 0 13 2.586V1a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1v1.586a8.053 8.053 0 0 0-1.97 1.152l-1.492-.84a1 1 0 0 0-1.352.361L.139 6.723a1.001 1.001 0 0 0 .37 1.38l1.559.88A7.829 7.829 0 0 0 2 10c0 .335.023.675.068 1.017l-1.56.88a.998.998 0 0 0-.37 1.38l2.048 3.464a.999.999 0 0 0 1.352.362l1.492-.842A7.99 7.99 0 0 0 7 17.413V19a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-1.587a8.014 8.014 0 0 0 1.97-1.152l1.492.842a1 1 0 0 0 1.353-.362l2.047-3.464a1.002 1.002 0 0 0-.37-1.38m-3.643-3.219c.1.448.15.893.15 1.322a6.1 6.1 0 0 1-.15 1.322 1 1 0 0 0 .484 1.09l1.287.725-1.03 1.742-1.252-.707a1 1 0 0 0-1.183.15 6.023 6.023 0 0 1-2.44 1.425 1 1 0 0 0-.715.96V18H9v-1.294a1 1 0 0 0-.714-.959 6.01 6.01 0 0 1-2.44-1.425 1.001 1.001 0 0 0-1.184-.15l-1.252.707-1.03-1.742 1.287-.726a.999.999 0 0 0 .485-1.089A6.043 6.043 0 0 1 4 10c0-.429.05-.874.152-1.322a1 1 0 0 0-.485-1.09L2.38 6.862 3.41 5.12l1.252.707a1 1 0 0 0 1.184-.149 6.012 6.012 0 0 1 2.44-1.426A1 1 0 0 0 9 3.294V2h2v1.294a1 1 0 0 0 .715.958c.905.27 1.749.762 2.44 1.426a1 1 0 0 0 1.183.15l1.253-.708 1.029 1.742-1.287.726a1 1 0 0 0-.484 1.09M9.999 6c-2.205 0-4 1.794-4 4s1.795 4 4 4c2.207 0 4-1.794 4-4s-1.793-4-4-4m0 6c-1.102 0-2-.897-2-2s.898-2 2-2c1.104 0 2 .897 2 2s-.896 2-2 2"></path>
		</g>
	</svg>
)

const discount = (
	<svg viewBox="0 0 20 20" focusable="false" width={20} height={20}>
		<g fillRule="evenodd">
			<path
				d="M7.742 17.655a2.205 2.205 0 0 0-1.558-.646h-.991a2.202 2.202 0 0 1-2.202-2.202v-.991c0-.584-.233-1.145-.646-1.558l-.7-.7a2.203 2.203 0 0 1 0-3.115l.7-.7c.413-.413.646-.974.646-1.558v-.991c0-1.216.986-2.202 2.202-2.202h.991c.584 0 1.145-.233 1.558-.646l.7-.7c.86-.86 2.255-.86 3.115 0l.7.7c.414.413.974.646 1.558.646h.991c1.216 0 2.202.986 2.202 2.202v.991c0 .584.233 1.145.646 1.558l.7.7c.86.86.86 2.255 0 3.115l-.7.7a2.205 2.205 0 0 0-.646 1.558v.991a2.202 2.202 0 0 1-2.202 2.202h-.991c-.584 0-1.144.233-1.558.646l-.7.7c-.86.86-2.255.86-3.115 0l-.7-.7z"
				fill="currentColor"></path>
			<path
				d="M19.06 7.734l-.7-.7a1.217 1.217 0 0 1-.353-.851v-.991a3.206 3.206 0 0 0-3.203-3.202h-.99c-.32 0-.623-.125-.85-.353l-.7-.7a3.207 3.207 0 0 0-4.53 0l-.7.7c-.229.228-.53.353-.852.353h-.99A3.206 3.206 0 0 0 1.99 5.192v.99c0 .317-.129.628-.352.852l-.7.7a3.207 3.207 0 0 0 0 4.529l.7.7c.223.224.352.534.352.85v.992a3.206 3.206 0 0 0 3.203 3.202h.99c.321 0 .623.125.851.353l.7.7a3.192 3.192 0 0 0 2.265.936c.82 0 1.64-.312 2.265-.936l.7-.7c.228-.228.53-.353.851-.353h.99a3.206 3.206 0 0 0 3.203-3.202v-.991c0-.317.13-.627.352-.851l.702-.7a3.208 3.208 0 0 0 0-4.53M12.288 6.29l-6 6a.999.999 0 1 0 1.414 1.414l6-6A.999.999 0 1 0 12.29 6.29M7.496 8.996a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3m5 2a1.5 1.5 0 1 0 .001 3.001 1.5 1.5 0 0 0 0-3m5.15-.148l-.702.7a3.181 3.181 0 0 0-.938 2.265v.99c0 .664-.539 1.203-1.203 1.203h-.99a3.18 3.18 0 0 0-2.265.939l-.7.7a1.205 1.205 0 0 1-1.702 0l-.7-.7a3.178 3.178 0 0 0-2.265-.94h-.99a1.203 1.203 0 0 1-1.203-1.201v-.991c0-.856-.333-1.66-.938-2.265l-.7-.7a1.203 1.203 0 0 1 0-1.701l.7-.7c.605-.605.938-1.41.938-2.265v-.991c0-.663.54-1.202 1.203-1.202h.99c.856 0 1.66-.333 2.265-.94l.7-.7a1.205 1.205 0 0 1 1.702 0l.7.7a3.18 3.18 0 0 0 2.265.94h.99c.664 0 1.203.539 1.203 1.202v.99c0 .856.333 1.66.938 2.266l.701.7a1.204 1.204 0 0 1 0 1.7"></path>
		</g>
	</svg>
)

const page = (
	<svg width={20} height={20} viewBox="0 0 32 32">
		<path d="M28.681 7.159c-0.694-0.947-1.662-2.053-2.724-3.116s-2.169-2.030-3.116-2.724c-1.612-1.182-2.393-1.319-2.841-1.319h-15.5c-1.378 0-2.5 1.121-2.5 2.5v27c0 1.378 1.122 2.5 2.5 2.5h23c1.378 0 2.5-1.122 2.5-2.5v-19.5c0-0.448-0.137-1.23-1.319-2.841zM24.543 5.457c0.959 0.959 1.712 1.825 2.268 2.543h-4.811v-4.811c0.718 0.556 1.584 1.309 2.543 2.268zM28 29.5c0 0.271-0.229 0.5-0.5 0.5h-23c-0.271 0-0.5-0.229-0.5-0.5v-27c0-0.271 0.229-0.5 0.5-0.5 0 0 15.499-0 15.5 0v7c0 0.552 0.448 1 1 1h7v19.5z"></path>
		<path d="M23 26h-14c-0.552 0-1-0.448-1-1s0.448-1 1-1h14c0.552 0 1 0.448 1 1s-0.448 1-1 1z"></path>
		<path d="M23 22h-14c-0.552 0-1-0.448-1-1s0.448-1 1-1h14c0.552 0 1 0.448 1 1s-0.448 1-1 1z"></path>
		<path d="M23 18h-14c-0.552 0-1-0.448-1-1s0.448-1 1-1h14c0.552 0 1 0.448 1 1s-0.448 1-1 1z"></path>
	</svg>
)

const article = (
	<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 28 28"><path d="M13.875 18.5l1.813-1.813-2.375-2.375-1.813 1.813V17H13v1.5h.875zM20.75 7.25c-.141-.141-.375-.125-.516.016l-5.469 5.469c-.141.141-.156.375-.016.516s.375.125.516-.016l5.469-5.469c.141-.141.156-.375.016-.516zM22 16.531V19.5c0 2.484-2.016 4.5-4.5 4.5h-13A4.502 4.502 0 010 19.5v-13C0 4.016 2.016 2 4.5 2h13c.625 0 1.25.125 1.828.391.141.063.25.203.281.359a.491.491 0 01-.141.453l-.766.766a.471.471 0 01-.5.125A2.697 2.697 0 0017.499 4h-13a2.507 2.507 0 00-2.5 2.5v13c0 1.375 1.125 2.5 2.5 2.5h13c1.375 0 2.5-1.125 2.5-2.5v-1.969c0-.125.047-.25.141-.344l1-1c.156-.156.359-.187.547-.109s.313.25.313.453zM20.5 5L25 9.5 14.5 20H10v-4.5zm6.938 2.063L26.001 8.5l-4.5-4.5 1.437-1.437a1.519 1.519 0 012.125 0l2.375 2.375a1.519 1.519 0 010 2.125z"/></svg>
)

const profile = (
	<svg width={20} height={20} viewBox="0 0 20 20" fill="#919eab">
		<g>
			<path
				d="M10 2a8 8 0 1 0 8 8 8.011 8.011 0 0 0-8-8zm0 14a5.943 5.943 0 0 1-3.28-.98 5.855 5.855 0 0 1 6.56 0A5.943 5.943 0 0 1 10 16zm4.78-2.39a7.95 7.95 0 0 0-9.56 0A5.887 5.887 0 0 1 4 10a6 6 0 0 1 12 0 5.887 5.887 0 0 1-1.22 3.61z"></path>
			<path d="M10 5a3 3 0 1 0 3 3 3.009 3.009 0 0 0-3-3zm0 4a1 1 0 1 1 1-1 1 1 0 0 1-1 1z"></path>
		</g>
	</svg>
)

const logout = (
	<svg height={20} width={20} viewBox="0 0 20 20" fill="#919eab">
		<path
			d="M10 16a1 1 0 1 1 0 2c-4.411 0-8-3.589-8-8s3.589-8 8-8a1 1 0 1 1 0 2c-3.309 0-6 2.691-6 6s2.691 6 6 6zm7.707-6.707a.999.999 0 0 1 0 1.414l-3 3a.997.997 0 0 1-1.414 0 .999.999 0 0 1 0-1.414L14.586 11H10a1 1 0 1 1 0-2h4.586l-1.293-1.293a.999.999 0 1 1 1.414-1.414l3 3z"
			fillRule="evenodd"></path>
	</svg>
)

const reports = (
	<svg width={20} height={20} viewBox="0 0 20 20">
		<g fillRule="evenodd">
			<path fill="currentColor" d="M7 5h6v10H7z"></path>
			<path
				d="M19 18a1 1 0 0 1 0 2H1a1 1 0 0 1 0-2h18zm0-18a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h5V5a1 1 0 0 1 1-1h5V1a1 1 0 0 1 1-1h6zm-5 14h4V2h-4v12zm-6 0h4V6H8v8zm-6 0h4v-4H2v4z"></path>
		</g>
	</svg>
)

const dotsGrid = (
	<svg width={12} height={12} viewBox="0 0 20 20">
		<path d="M7 2c-1.104 0-2 .896-2 2s.896 2 2 2 2-.896 2-2-.896-2-2-2zm0 6c-1.104 0-2 .896-2 2s.896 2 2 2 2-.896 2-2-.896-2-2-2zm0 6c-1.104 0-2 .896-2 2s.896 2 2 2 2-.896 2-2-.896-2-2-2zm6-8c1.104 0 2-.896 2-2s-.896-2-2-2-2 .896-2 2 .896 2 2 2zm0 2c-1.104 0-2 .896-2 2s.896 2 2 2 2-.896 2-2-.896-2-2-2zm0 6c-1.104 0-2 .896-2 2s.896 2 2 2 2-.896 2-2-.896-2-2-2z"></path>
	</svg>
)

const edit = (
	<svg width={16} height={16} viewBox="0 0 32 32"><path fill="none" d="M11.2 27.2l16-16-6.4-6.4-16 16z"></path><path fill="currentColor" d="M30.205 1.736c-2.312-2.314-6.347-2.314-8.662 0L3.67 19.61c-.172.17-.287.373-.364.587-.005.014-.02.024-.024.038l-3.2 9.6c-.192.574-.042 1.21.387 1.637.3.306.71.47 1.13.47.17 0 .34-.027.5-.083l9.6-3.2.04-.03c.214-.077.416-.19.587-.363L30.2 10.393C31.363 9.24 32 7.703 32 6.066c0-1.636-.638-3.173-1.795-4.33zm-2.262 6.4l-.742.742L23.07 4.74l.742-.742c1.105-1.106 3.03-1.106 4.137 0 .554.554.858 1.288.858 2.07s-.303 1.514-.857 2.068zM5.5 23.702l2.74 2.74-4.11 1.37L5.5 23.7zm5.7 1.176L7.064 20.74 20.8 7.002l4.14 4.138L11.2 24.878z"></path></svg>
)

const media = (
	<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox={'0 0 32 32'}><path d="M0 4v24h32V4H0zm6 22H2v-4h4v4zm0-8H2v-4h4v4zm0-8H2V6h4v4zm18 16H8V6h16v20zm6 0h-4v-4h4v4zm0-8h-4v-4h4v4zm0-8h-4V6h4v4zm-18 0v12l8-6z"/></svg>
)

const star = (
	<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox={'0 0 32 32'}><path d="M32 12.408l-11.056-1.607L16 .783l-4.944 10.018L0 12.408l8 7.798-1.889 11.011L16 26.018l9.889 5.199L24 20.206l8-7.798zM16 23.547l-.029.015L16 5.725l3.492 7.075 7.807 1.134-5.65 5.507 1.334 7.776L16 23.546z"/></svg>
)

const locked = (
	<svg className={'locked'} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path d="M15.8 8H14V5.6C14 2.703 12.665 1 10 1S6 2.703 6 5.6V8H4c-.553 0-1 .646-1 1.199V17c0 .549.428 1.139.951 1.307l1.197.387A7.731 7.731 0 007.1 19h5.8a7.68 7.68 0 001.951-.307l1.196-.387c.524-.167.953-.757.953-1.306V9.199C17 8.646 16.352 8 15.8 8zM12 8H8V5.199C8 3.754 8.797 3 10 3s2 .754 2 2.199V8z" fill={'currentColor'} /></svg>
)
const unlock = (
	<svg className={'unlock'} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path d="M15.8 8H14V5.6C14 2.703 12.665 1 10 1S6 2.703 6 5.6V6h2v-.801C8 3.754 8.797 3 10 3s2 .754 2 2.199V8H4c-.553 0-1 .646-1 1.199V17c0 .549.428 1.139.951 1.307l1.197.387A7.731 7.731 0 007.1 19h5.8a7.68 7.68 0 001.951-.307l1.196-.387c.524-.167.953-.757.953-1.306V9.199C17 8.646 16.352 8 15.8 8z" fill={'currentColor'}/></svg>
)

export const icons = {
	logo: logo,
	home: home,
	products: products,
	discount: discount,
	orders: orders,
	customers: customers,
	settings: settings,
	page: page,
	article: article,
	logout: logout,
	reports: reports,
	profile: profile,
	dotsGrid: dotsGrid,
	edit: edit,
	media: media,
	star: star,
	locked: locked,
	unlock: unlock
}
