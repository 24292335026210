import React, {useEffect, useState, useCallback, useContext} from 'react'
import {useTranslation} from "react-i18next"
import {fetchOrdersList, searchOrders} from "./container"
import {authContext} from "authentication/auth"
import {getParam, urlParams, getParams} from "helpers/utils"
import axios from "axios"
import OrdersListView from "./orderListView"

const OrdersListDone = () => {
	const [data, setData] = useState({ready: false, last_page: 1, current_page: 1, isSearched: false})
	const [appliedFilters, setAppliedFilters] = useState({})
	const [selectedItems, setSelectedItems] = useState([])
	const {t} = useTranslation()
	let currentPage = 1
	const source = axios.CancelToken.source()

	const userAuth = useContext(authContext)
	const sellerId = userAuth ? userAuth.user.seller_id : ''

	useEffect(() => {
		document.title = t('Đơn hàng giao thành công')
		const params = getParams()
		if (urlParams.has('page')) {
			currentPage = parseInt(getParam('page')) || 1
		}
		let result, isSearched = false
		const fetchData = async () => {
			try {
				if (Object.keys(params).length > 0) {
					params.seller_id = sellerId
					params.order_status = 'shipping'
					result = await searchOrders(params, source.token)

					if (result) isSearched = true
				} else {
					result = await fetchOrdersList({page: currentPage, seller_id: sellerId, order_status: 'shipped'}, source.token)
				}
				if (result) {
					setData({...data, ...result, ready: true, isSearched: isSearched})
				}
			} catch (error) {
				if (axios.isCancel(error)) {
					console.log('is canceled')
				} else {
					throw error;
				}
			}
		}
		fetchData()
		return () => source.cancel()
	}, [window.location.search])

	const handleSearch = () => {
		const params = getParams()
		params.seller_id = sellerId
		params.order_status = 'shipping'
		;(async () => {
			const result = await searchOrders(params, source.token)
			setData({...result, ready: true, isSearched: true})
		})()
	}

	return (
		<OrdersListView
			title={'Đơn hàng giao thành công'}
			data={data}
			handleSearch={handleSearch}
			appliedFilters={appliedFilters}
			selectedItems={selectedItems}
			setSelectedItems={setSelectedItems}
			setAppliedFilters={setAppliedFilters}
		/>
	)
}
export default OrdersListDone
