import React, {useCallback, useContext, useState} from 'react'
import {Card, Button, ButtonGroup, Badge} from '@shopify/polaris'
import {useSubscription} from "global-state-hook"
import {useTranslation} from "react-i18next"
import AddItems from "pages/orders/components/addItems"
import Table from "components/table"
import {itemInitalState, OrderContext, updateOrder} from "pages/orders/container"
import {DeleteMinor, EditMinor} from '@shopify/polaris-icons'
import {showToast} from "plugins/toast"

export default function Summary({user}) {

	const orderSub = useContext(OrderContext)
	let {setState, state} = useSubscription(orderSub)
	const [active, setActive] = useState(false)
	const [initialState, setInitialState] = useState(itemInitalState)
	const [currentIndex, setCurrentIndex] = useState(-1)
	const {line_items, _id, order_status, weight, width, height, length} = state
	const {t} = useTranslation()
	const handleToggle = useCallback(() => setActive((active) => !active), [])

	const handleClose = () => {
		setInitialState(itemInitalState)
		setCurrentIndex(-1)
		handleToggle()
	}

	const handleEdit = (index) => {
		setInitialState(line_items[index])
		setCurrentIndex(index)
		handleToggle()
	}

	// const handleEdit = (isNew, index) => {
	// 	if (isNew) {
	// 		setInitialState({...itemInitalState})
	// 	} else {
	// 		setInitialState(line_items[index])
	// 		setCurrentIndex(index)
	// 	}
	// 	handleToggle()
	// }
	const handleDelete = (index) => {
		line_items.splice(index, 1)
		if (_id) {
			updateOrder({_id: _id, order_status: order_status, line_items: line_items}).then((res) => {
				const result = res.data
				const {data, success} = result
				if (res && result && success) {
					const {width, height, length, weight} = data
					setState({line_items: data.line_items, width: width, height: height, weight: weight, length: length})
					showToast({
						message: 'Cập nhật thành công'
					})
				} else {
					showToast({
						message: 'Cập nhật không thành công, vui lòng thử lại',
						error: true
					})
				}
			}).catch((error) => {
				console.error(error)
			})
		} else {
			setState({line_items: line_items})
		}
	}

	return (
		<Card>
			<Card.Header title={t('Tổng quan')}>
				{line_items.length < 1 && <Button plain onClick={handleToggle}>Thêm sản phẩm</Button>}
			</Card.Header>
			{line_items && line_items.length ? (
				<Card.Section subdued>
					<Table bordered>
						<thead>
						<tr>
							<th>Loại sản phẩm</th>
							<th colSpan={2}>Số lượng</th>
						</tr>
						</thead>
						<tbody>
						{line_items.map((item, index) => {
							const {mockup, design, quantity, item_id, title, color, laser} = item
							return (
								<tr key={index}>
									<td>
										<span style={{fontWeight: 500}}>{title}</span> ({item_id})
										{color && <div style={{marginTop: 10}}>Màu: <strong>{color}</strong></div>}
									</td>
									<td width={120}>x{quantity} cái</td>
									<td width={300} className={'text-right'}>
										<ButtonGroup>
											<Button plain external url={design}>Thiết kế</Button>
											<Button plain external url={mockup}>Mockup</Button>
											{laser && <Button plain external url={laser}>Laser</Button>}
											{order_status === 'open' && <Button icon={EditMinor} plain onClick={() => handleEdit(index)} />}
											{order_status === 'open' && <Button icon={DeleteMinor} plain destructive onClick={() => handleDelete(index)} />}
										</ButtonGroup>
									</td>
								</tr>
							)
						})}
						{order_status === 'open' && line_items && line_items.length > 0 && <tr>
							<td colSpan={4}>
								<Button onClick={handleToggle}>Thêm sản phẩm</Button>
							</td>
						</tr>}
						</tbody>
					</Table>
				</Card.Section>
			) : (
				<Card.Section>
					<p>Chưa có sản phẩm nào</p>
				</Card.Section>
			)}
			{active && <AddItems user={user} open={true} currentIndex={currentIndex} handleClose={handleClose} initialState={initialState} handleToggle={handleToggle} />}
		</Card>
	)
}
