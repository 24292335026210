import React, {useState, useContext, useCallback} from 'react'
import {Card, Link, Button, Badge} from '@shopify/polaris'
import {OrderContext} from "pages/orders/container"
import {useSubscription} from "global-state-hook"

export default function Shipping() {
	const orderSub = useContext(OrderContext)
	let {setState, state} = useSubscription(orderSub)
	const {shipping_tracking_number, shipping_carrier} = state
	const trackingUrl = shipping_carrier === 'other' ? `https://t.17track.net/en#nums=${shipping_tracking_number}` : `https://webtrack.dhlglobalmail.com/?trackingnumber=${shipping_tracking_number}`
	return (
		<Card>
			<Card.Header title={'Vận chuyển'}>
				{shipping_tracking_number && <Link external url={trackingUrl}>Xem Tracking</Link>}
			</Card.Header>
			<Card.Section>
				<p>Mã Tracking: <Badge><strong>{shipping_tracking_number ? shipping_tracking_number : 'Chưa cập nhật'}</strong></Badge></p>
			</Card.Section>
		</Card>
	)
}
